.FieldInputDate

	display: block

	:global(.react-datepicker),
	:global(.react-datepicker__header)

		font-family: inherit
		background: var(--color-island)
		border: none
		color: red

	:global(.react-datepicker__header) div

		color: var(--color-text-strong)

	:global(.react-datepicker__day)

		color: var(--color-text)
		font-weight: 200

	:global(.react-datepicker__day):hover

		color: var(--color-text-strong)
		background: none

	:global(.react-datepicker__day--selected),
	:global(.react-datepicker__day--selected):hover,
	:global(.react-datepicker__day--selected.react-datepicker__day--today),
	:global(.react-datepicker__day--selected.react-datepicker__day--today):hover

		color: #fff
		background: var(--color-action)

	:global(.react-datepicker__day--today)

		background: #ffffff10
		border-radius: var(--base-radius)

	:global(.react-datepicker__day--today):hover

		background: #ffffff10
		border-radius: var(--base-radius)
