:root

	@import url('https://fonts.googleapis.com/css2?family=Inter:wght@886&display=swap')

body

	margin: 0
	font-family: 'Inter', sans-serif
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
