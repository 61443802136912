.FieldInputText

	input,
	textarea

		padding: 10px
		appearance: none
		border: .1rem solid var(--color-action)
		width: 100%
		border-radius: var(--base-radius)
		background: var(--color-island)
		color: white
		font-feature-settings: "cv02","cv03","cv04","cv11"
		line-height: 1.5
		margin-bottom: 1rem
		font-family: 'Roboto', sans-serif

		&::-webkit-input-placeholder

			color: #aaa

		&:focus

			outline: none
			border-color: white
