.deviantDiscussionPage

	.Layout

		display: flex
		flex-direction: column
		margin-bottom: 4rem

		@media (min-width: 550px)

			flex-direction: row

.layoutDetails

	flex: 1
	flex-basis: 300px
	width: 300px
	text-align: left
	padding-right: 2rem

.layoutOutput

	flex: 1
	max-width: 600px

pre

	white-space: pre-wrap

.childCard

	text-align: left
	color: white
	font-family: Arial, Helvetica, sans-serif
