@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,900;1,100;1,300&display=swap')

:root

	--color-ocean: #0f172a
	--color-island: #1e293b
	--color-island-alt: #0c121b
	--color-text: #8291a6
	--color-text-strong: #d6e2f2
	--color-action: #0ea5e9
	--base-radius: 0.3rem

	background: var(--color-ocean)
	color: var(--color-text)
	font-feature-settings: "cv02","cv03","cv04","cv11"
	-webkit-text-size-adjust: 100%
	tab-size: 4
	font-family: 'Roboto', sans-serif
	-webkit-font-smoothing: antialiased
	-webkit-text-size-adjust: 100%
	line-height: 1.5
	font-weight: 100
	padding: 0
	margin: 0

*,
*:before,
*:after

	box-sizing: border-box
	border: 0 solid #e5e7eb

.App

	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	// height: 100vh
	// text-align: center
	// min-height: 100vh
	// overflow-x: hidden

h1, h2, h3, h4, h5, h6

	color: var(--color-text-strong)
	letter-spacing: -.025em
	font-weight: 800
	margin-top: 0

h1

	font-size: 1.875rem
	line-height: 2.25rem
	margin-block-start: 0.67em
	margin-block-end: 0.67em
	margin-inline-start: 0px
	margin-inline-end: 0px

a, a:visited, a:hover, a:active

	color: var(--color-action)
	text-decoration: none

nav

	// position: fixed
	// top: 0
	// left: 0
	// height: 100vh
	width: 100vw
	background: var(--color-island-alt)
	display: flex
	flex-direction: row
	justify-content: center
	align-items: center
	padding: 1rem
	z-index: 1000000

	a

		display: block
		padding: .5rem

main

	padding: 2rem
	flex-grow: 1
	// overflow: x-scroll
