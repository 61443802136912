.PageMultipleDiscordTime

	diplay: block

	.timeListing

		text-align: left

	.actionsBar

		button

			margin-right: 1rem

	.layoutTimePoll

		background: var(--color-island-alt)
		padding: 1rem
		margin: 0
		max-width: 550px
		margin: 0 auto

		li

			margin: 0
