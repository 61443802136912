.CopyTextToClipboard

	display: block
	font-family: Courier New, Courier, monospace
	padding: 1rem
	border: 1px solid var(--color-island)
	cursor: pointer
	position: relative

	&:hover

		background-color: var(--color-island)
		color: var(--color-white)

	.clickToCopyText

		color: white
		font-family: 'Inter', sans-serif
		margin-bottom: 1rem

	.copied

		font-family: roboto
		font-weight: bold
		text-align: center
		font-size: 1.5rem
		width: 100%
		height: 100%
		display: flex
		align-items: center
		justify-content: center
		position: absolute
		top: 0
		left: 0
		background: var(--color-island)
		color: white
		opacity: 0
		transition: opacity .5s ease-in-out
		z-index: 10000

	&.wasCopied

		.copied

			transition: opacity 0s ease-in-out
			opacity: 1
