.PageDiscordTime

	diplay: block

	.layout

		display: flex
		justify-content: center
		align-items: flex-start
		gap: 1rem
		flex-direction: column

		@media (min-width: 550px)

			flex-direction: row

	.childCard

		margin-bottom: 1rem

		h3

			margin: 0

		p

			margin: 0 0 1rem 0

	.cardContainer

		background: var(--color-island-alt)
		padding: 1rem
		border-radius: var(--base-radius)
		display: block
		text-align: left

		span

			color: white

	.timezoneList

		margin: 0
		list-style: none
		padding: 0

		li

			margin: 0
			padding: 0
