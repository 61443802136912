.VisuallyHidden,
.VisuallyHiddenShowOnFocus:not(:focus) {

	/*
	The use of important is contextually relevent here as
	this content needs to not be overiadable.
	*/

	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;

}
