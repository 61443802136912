.PageSEPracticeTools

	.Layout

		display: inline-flex
		flex-direction: column
		margin-bottom: 2rem

	@media (min-width: 550px)

		.Layout

			flex-direction: row

.layoutDetails

	flex: 1
	background: var(--color-island)
	border-radius: var(--base-radius)
	padding: 1rem
	text-align: left
	margin: 0 0 1rem 0

	@media (min-width: 550px)

		margin: 0 1rem 1rem 0

.layoutOutput

	flex: 1

.childCard

	text-align: left
	color: white
	font-family: Arial, Helvetica, sans-serif
